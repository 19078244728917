exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bank-js": () => import("./../../../src/pages/bank.js" /* webpackChunkName: "component---src-pages-bank-js" */),
  "component---src-pages-gary-js": () => import("./../../../src/pages/gary.js" /* webpackChunkName: "component---src-pages-gary-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-ramp-terms-of-service-js": () => import("./../../../src/pages/legal/ramp-terms-of-service.js" /* webpackChunkName: "component---src-pages-legal-ramp-terms-of-service-js" */),
  "component---src-pages-legal-terms-of-service-js": () => import("./../../../src/pages/legal/terms-of-service.js" /* webpackChunkName: "component---src-pages-legal-terms-of-service-js" */),
  "component---src-pages-martin-js": () => import("./../../../src/pages/martin.js" /* webpackChunkName: "component---src-pages-martin-js" */),
  "component---src-pages-nick-js": () => import("./../../../src/pages/nick.js" /* webpackChunkName: "component---src-pages-nick-js" */),
  "component---src-pages-nino-js": () => import("./../../../src/pages/nino.js" /* webpackChunkName: "component---src-pages-nino-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-petko-js": () => import("./../../../src/pages/petko.js" /* webpackChunkName: "component---src-pages-petko-js" */),
  "component---src-pages-supported-js": () => import("./../../../src/pages/supported.js" /* webpackChunkName: "component---src-pages-supported-js" */),
  "component---src-pages-token-js": () => import("./../../../src/pages/token.js" /* webpackChunkName: "component---src-pages-token-js" */),
  "component---src-pages-wallet-js": () => import("./../../../src/pages/wallet.js" /* webpackChunkName: "component---src-pages-wallet-js" */),
  "component---src-pages-wealth-js": () => import("./../../../src/pages/wealth.js" /* webpackChunkName: "component---src-pages-wealth-js" */)
}

